import React from 'react';
import './AboutUs.css';

function AboutUs() {
  return (
    <section id="About" className="section about-section">
      <div className="content-container">
        <div className="text-left">
        <h1>
          <span>Who</span> <span>We</span> <span>Are</span>
        </h1>
        </div>
        <div className="text-right">
<div class="about-us-container">
    <div class="terminal_toolbar">
        <div class="butt">
            <button class="btn btn-color"></button>
            <button class="btn"></button>
            <button class="btn"></button>
        </div>
        <p class="user">trikalya@admin: ~</p>
        <div class="add_tab">
            +
        </div>
    </div>
    <div class="terminal_body">
        <div class="terminal_promt">
            <span class="terminal_user">trikalya@admin:</span>
            <span class="terminal_location">~</span>
            <span class="">aboutus -v</span>
            {/* <span class="terminal_bling">$</span>
            <span class="terminal_cursor"></span> */}
        </div>
        <div class="ter_content">
        <span class="terninal_body">Trikalya represents the union of past, present, and future, embodying a forward-thinking approach to solving today’s challenges. We are dedicated to creating innovative solutions that simplify financial management, enhance shopping experiences, and boost individual performance. Our goal is to empower users to navigate their daily tasks effortlessly, blending tradition with modern technology to deliver tools that are intuitive, impactful, and designed for the future.</span>
        </div>
        <div class="terminal_promt">
            <span class="terminal_user">trikalya@admin:</span>
            <span class="terminal_location">~</span>
            <span class="terminal_bling">$</span>
            <span class="terminal_cursor"></span>
        </div>
    </div>
</div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
