import React from 'react';
import './Products.css';

function Products() {
  return (
    <section id="Products" className="section products-section">
      <div className="container">
        {/* <h1 className="ae-1">Our Expertise</h1> */}
        <p className="ae-2">
          <span className="opacity-8">
            At Trikalya, we harness the power of innovation to address real-world needs
          </span>
        </p>
        <div className="cards-container">
          <div className="card">
            <h2 className="card-heading">Finance Management</h2>
            <p className="card-content">Our solutions make managing your money easier, providing clarity and control over your financial future with intuitive budgeting, tracking, and planning tools.</p>
          </div>
          <div className="card">
            <h2 className="card-heading">Smart Shopping</h2>
            <p className="card-content">We enhance your shopping experience by offering solutions that are as smart as they are simple, ensuring every transaction feels seamless and personalized.</p>
          </div>
          <div className="card">
            <h2 className="card-heading">Performance Enhancement</h2>
            <p className="card-content">Unlock your potential with our innovative applications designed to refine skills and boost confidence, preparing you for success in every endeavor.</p>
          </div>
        </div>
        <p className="footer-text">
          <span className="opacity-8">
            By combining insights from the past, solutions for the present, and visions for the future, Trikalya turns complexity into simplicity.
          </span>
        </p>
        {/* Uncomment if needed */}
        {/* <a className="button blue gradient crop ae-3">Get Started</a> */}
      </div>
    </section>
  );
}

export default Products;
