import React, { useState, useEffect } from 'react';
import './Contact.css';
import EmailIcon from '@mui/icons-material/Email';
import Swal from 'sweetalert2'

function Contact() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: formData.fullName,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
      access_key: process.env.REACT_APP_EMAIL_ACCESS_KEY // Replace with your actual access key
    };

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify(data)
      });
      const result = await res.json();
      if (result.success) {
        Swal.fire({
          title: "Success",
          text: "Your message is successfully sent",
          icon: "success",
        });
        
        setFormData({
          fullName: '',
          email: '',
          subject: '',
          message: ''
        });
      } else {
        console.error("Error", result);
        alert("There was a problem sending your message.");
      }
    } catch (error) {
      console.error("Error", error);
      alert("There was a problem sending your message.");
    }
  };

  // Optional: Reset form when navigating to a different page
  useEffect(() => {
    return () => {
      setFormData({
        fullName: '',
        email: '',
        subject: '',
        message: ''
      });
    };
  }, []);

  return (
    <section id="Contact" className="contact-section">
      <div className="Contact-container">
        <div className="contact-left">
          <h1>Let's chat.<br />Tell us about your<br />Project</h1>
          <p>Let's create something together</p>

          {/* Email section with logo */}
          <div className="email-info">
            <a href="mailto:contact@trikalya.com" target="_blank" rel="noopener noreferrer">
              <EmailIcon className="email-icon" />
              <span className="email-text">contact@trikalya.com</span>
            </a>
          </div>
        </div>

        <div className="contact-right">
          <div className="contact-card">
            <h2 className="card-h1">Send us a message</h2>
            <form onSubmit={handleSubmit} className="contact-form">
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
                placeholder='Enter your full name'
              />

              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder='Enter your email address'
              />

              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                placeholder='Enter your subject'
              />

              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder='Enter your message'
                className="form-me"
              ></textarea>

              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
