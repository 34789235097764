// src/App.js
import React from 'react';
import './App.css';
import Header from './Header/header';
import Home from './Home/Home';
import About from './AboutUs/AboutUs';
import Products from './Products/Products';
import WhyUs from './WhyUs/WhyUs';
import Services from './Services/Services';
import Contact from './Contact/Contact';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <section id="Home" className="section"><Home /></section>
        <section id="About" className="section"><About /></section>
        <section id="Products" className="section"><Products /></section>
        <section id="WhyUs" className="section"><WhyUs /></section>
        <section id="Services" className="section"><Services /></section>
        <section id="Contact" className="section"><Contact /></section>
      </main>
    </div>
  );
}

export default App;
