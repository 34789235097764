import React from 'react';
import './WhyUs.css';

function WhyUs() {
  return (
    <section id="WhyUs" className="whyus-section">
      <div className="whyus-container">
        <h1 className="Why-Head">Your Partner in Innovation</h1>
        <div className="why-content">
          <p><b>Visionary Approach:</b> We don’t just solve problems; we envision the future, crafting solutions that evolve with your needs.</p>
          <p><b>Tailored Solutions:</b> Every challenge is unique, and so are our solutions. We customize our approach to fit your goals and aspirations.</p>
          <p><b>Commitment to Excellence:</b> Our passion lies in delivering quality and value, ensuring that our solutions not only meet but exceed your expectations.</p>
        </div>
        <p className="whytag slide-in">-- With Trikalya, you’re not just adapting to the future—you’re defining it --</p>
      </div>
    </section>
  );
}

export default WhyUs;
