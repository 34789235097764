import React, { useState, useEffect } from 'react';
import './header.css';
import LinkedIn from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

function Header() {
  const [activeSection, setActiveSection] = useState('Home');

  useEffect(() => {
    const sections = document.querySelectorAll('section');

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <header className="header">
      <div className="header-left">
        <a href="https://trikalya.com">
          <img src="/Assets/imgs/Header/trikalya12.png" alt="Logo" className="logo" />
        </a>
      </div>

      <div className="header-middle">
        <div className="menu-container">
          <ul className="menu-list">
            <li className="menu-item">
              <a href="#Home" className={`menu-link ${activeSection === 'Home' ? 'active' : ''}`}>Home</a>
            </li>
            <li className="menu-item">
              <a href="#About" className={`menu-link ${activeSection === 'About' ? 'active' : ''}`}>About</a>
            </li>
            <li className="menu-item">
              <a href="#Products" className={`menu-link ${activeSection === 'Products' ? 'active' : ''}`}>Products</a>
            </li>
            <li className="menu-item">
              <a href="#WhyUs" className={`menu-link ${activeSection === 'WhyUs' ? 'active' : ''}`}>Why Us</a>
            </li>
            <li className="menu-item">
              <a href="#Services" className={`menu-link ${activeSection === 'Services' ? 'active' : ''}`}>Services</a>
            </li>
            <li className="menu-item">
              <a href="#Contact" className={`menu-link ${activeSection === 'Contact' ? 'active' : ''}`}>Contact</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="header-right">
          <a href="https://www.linkedin.com/company/trikalya/" target="_blank" rel="noopener noreferrer">
            <LinkedIn style={{ fontSize: 24, color: "#ffffff" }} />
          </a>
          <a href="https://www.instagram.com/trikalya/" target="_blank" rel="noopener noreferrer">
            <InstagramIcon style={{ fontSize: 24, color: '#ffffff' }} />
          </a>
          <a href="https://x.com/TrikalyaS" target="_blank" rel="noopener noreferrer">
            <TwitterIcon style={{ fontSize: 24, color: '#ffffff' }} />
          </a>
      </div>

      {/* Side Navigation Bar */}
      <nav className="side-scroll">
        <ul className="scroll-list">
          <li className="scroll-item">
            <a href="#Home" className={`scroll-link ${activeSection === 'Home' ? 'active' : ''}`}>
              <div className={`nav-dot ${activeSection === 'Home' ? 'active' : ''}`}></div>
            </a>
          </li>
          <li className="scroll-item">
            <a href="#About" className={`scroll-link ${activeSection === 'About' ? 'active' : ''}`}>
              <div className={`nav-dot ${activeSection === 'About' ? 'active' : ''}`}></div>
            </a>
          </li>
          <li className="scroll-item">
            <a href="#Products" className={`scroll-link ${activeSection === 'Products' ? 'active' : ''}`}>
              <div className={`nav-dot ${activeSection === 'Products' ? 'active' : ''}`}></div>
            </a>
          </li>
          <li className="scroll-item">
            <a href="#WhyUs" className={`scroll-link ${activeSection === 'WhyUs' ? 'active' : ''}`}>
              <div className={`nav-dot ${activeSection === 'WhyUs' ? 'active' : ''}`}></div>
            </a>
          </li>
          <li className="scroll-item">
            <a href="#Services" className={`scroll-link ${activeSection === 'Services' ? 'active' : ''}`}>
              <div className={`nav-dot ${activeSection === 'Services' ? 'active' : ''}`}></div>
            </a>
          </li>
          <li className="scroll-item">
            <a href="#Contact" className={`scroll-link ${activeSection === 'Contact' ? 'active' : ''}`}>
              <div className={`nav-dot ${activeSection === 'Contact' ? 'active' : ''}`}></div>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
