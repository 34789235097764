import './Services.css';

function Services() {
  return (
    <section id="Services" className="section services-section">
      <div class="Ser-Cards-Container">
      <div class="notification">
      <div class="notiglow"></div>
    <div class="notiborderglow"></div>
    <div class="notititle">Optimize Your Infrastructure with Cloud & DevOps</div>
    <div class="notibody">Maximize your operational efficiency with Trikalya’s cloud and DevOps services. We help businesses migrate, manage, and optimize their cloud environments, ensuring security, scalability, and performance. Our DevOps expertise streamlines development processes, accelerates delivery, and reduces costs, providing you with the agility to adapt and grow in today’s competitive landscape.</div>
  </div>
  <div class="notification">
    <div class="notiglow"></div>
    <div class="notiborderglow"></div>
    <div class="notititle">Build Once, Deploy Everywhere</div>
    <div class="notibody">We specialize in cross-platform application development, creating solutions that work seamlessly across Android, iOS, and web platforms. By leveraging the latest technologies, we deliver applications that are not only visually appealing but also high-performing and easy to maintain. Whether you’re looking to launch a new app or optimize an existing one, Trikalya ensures your vision is brought to life with unmatched quality and efficiency.</div>
  </div>
  <div class="notification">
    <div class="notiglow"></div>
    <div class="notiborderglow"></div>
    <div class="notititle">Master Your Database with Oracle Expertise</div>
    <div class="notibody">Secure, optimize, and manage your data with Trikalya’s Oracle Database Administration services. We offer end-to-end solutions, including setup, tuning, backup, and monitoring, ensuring your database performs at its best. Our proactive management approach minimizes downtime and maximizes performance, allowing you to focus on driving your business forward.</div>
  </div>
      </div>
    </section>
  );
}

export default Services;
