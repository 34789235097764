import React from 'react';
import './Home.css'; // Import your CSS file

function Home() {
  return (
    <section id="Home" className="Home">
      <div className="">
      <h1 className="btn-shine">Empowering Your Present, Shaping Your Future</h1>
      {/* <h3 className="Subhead">Innovative Solutions for Finance, Shopping, and Growth</h3> */}
        </div>
    </section>
  );
}

export default Home;
